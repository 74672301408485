<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions(["setPages", "setProjects"]),
  },
  computed: {
    langChange() {
      const locale = this.$route.fullPath.includes("/en/") ? "en" : "fr";
      return locale;
    },
  },
  watch: {
    langChange: () => {
      console.log(`locale`, this.$i18n);
      this.$i18n.locale = locale;
    },
  },
  mounted() {
    this.setPages();
    this.setProjects();
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Cinzel&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");
.slick-slider {
  height: 100%;
  .slick-list {
    height: 100%;
    overflow: hidden;
    .slick-track {
      height: 100%;
      display: flex;
    }
    .slick-slide > div {
      height: 100%;
    }
  }
}
.arrow-container {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  box-shadow: 0 0 11px 0 rgba(255, 255, 255, 0.5);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  cursor: pointer;
  svg {
    margin-left: -2px;
  }
  &.prev {
    left: 0;
    transform: translate(-50%, -50%);
  }
  &.next {
    right: 0;
    transform: translate(50%, -50%) rotate(180deg);
  }
}
body,
html {
  scroll-behavior: smooth;
  padding: 0;
  margin: 0 auto;
  background: transparent;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-family: "Ubuntu";
  width: 100%;
}
* {
  text-rendering: optimizeLegibility;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}
*:focus {
  outline: 0;
}

button {
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-family: inherit;
  background-color: transparent;
}
.container {
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
  padding: 0 30px;
  height: 100%;
  z-index: 10;
  position: relative;
}
h1,
h2 {
  font-size: 44px;
  font-family: "Ubuntu";
  font-weight: 500;
  color: rgb(255, 255, 255);
  line-height: 52px;
  text-align: left;
  margin: 0;
  padding: 0;
}
section {
  position: relative;
}
picture.background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.3;
  img {
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;
  }
}
</style>
