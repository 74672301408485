import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Floor from "../views/Floor.vue";
import NotFound from "../views/NotFound.vue";
import Contact from "../views/Contact";
Vue.use(VueRouter);

const routes = [
  {
    path: "/404",
    component: NotFound,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    params: {
      lang: "fr",
    },
  },
  {
    path: "/en/",
    name: "Home_ru",
    component: Home,
    params: {
      lang: "en",
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    params: {
      lang: "fr",
    },
  },
  {
    path: "/en/contact",
    name: "Contact_ru",
    component: Contact,
    params: {
      lang: "en",
    },
  },
  {
    path: "/:floor",
    name: "Floors",
    component: Floor,
    params: {
      lang: "fr",
    },
  },
  {
    path: "/en/:floor",
    name: "Floors_ru",
    component: Floor,
    params: {
      lang: "en",
      lol: "lol",
    },
  },
  {
    path: "*",
    name: "404",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
