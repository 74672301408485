<template>
  <section>
    <menuComponent :names="[]" :lang="lang" />
    <footerComponent :lang="lang" />
    <picture class="background">
      <img :src="form_background.url" alt="" />
    </picture>
    <section class="form" v-if="form_heading && form_heading[lang]">
      <div class="container">
        <div class="form-wrap">
          <div class="info">
            <h1>{{ form_heading[lang] }}</h1>
            <div class="more-info">
              <p>
                <span>{{ contacts.address[lang].fields }}: </span
                >{{ contacts.address[lang].value }}
              </p>
              <br />
              <p v-for="(phone, i) in contacts.phone" :key="i">
                <span>{{ phone[lang].name }}:</span>
                {{ phone[lang].value }}
              </p>
              <br />
              <p>
                <span>{{ contacts.email[lang].fields }}: </span
                >{{ contacts.email[lang].value }}
              </p>
              <br />
              <p v-for="(hours, i) in contacts.phone_copy" :key="i">
                <span>{{ hours[lang] }}:</span>
                {{ hours.value }}
              </p>
              <br />
              <p>
                <span>{{
                  contacts.germany.official_representative[lang]
                }}</span>
              </p>
              <p>{{ contacts.germany.name }}</p>
              <p>
                <span>{{ contacts.germany.phone[lang] }}:</span>
                {{ contacts.germany.phone.value }}
              </p>
              <p>
                <span>{{ contacts.germany.email[lang] }}:</span>
                {{ contacts.germany.email.value }}
              </p>
            </div>
          </div>
          <div class="fields">
            <div class="heading">{{ form_sub_heading[lang] }}</div>
            <form @submit.prevent="validate" novalidate>
              <div class="input-container">
                <input
                  ref="name"
                  v-on:blur="loseFocus($refs.name)"
                  v-model="formDetails.name"
                  :class="{ full: formDetails.name.length > 0 }"
                  maxlength="60"
                  required="required"
                  name="name"
                  class="int-input name"
                  value=""
                  type="text"
                />
                <span class="input-about"
                  >{{ form_fields.name[lang] }}<span> *</span>
                  <span class="error">{{ form_fields.name_error[lang] }}</span>
                </span>
                <svg
                  width="300%"
                  height="50px"
                  viewBox="0 0 1200 60"
                  preserveAspectRatio="none"
                  class="graphic"
                >
                  <path
                    d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0"
                  ></path>
                </svg>
              </div>
              <div class="input-container">
                <input
                  ref="email"
                  v-on:blur="loseFocus($refs.email)"
                  v-model="formDetails.email"
                  :class="{ full: formDetails.email.length > 0 }"
                  maxlength="60"
                  required="required"
                  name="email"
                  class="int-input email"
                  value=""
                  type="text"
                />
                <span class="input-about"
                  >{{ form_fields.email[lang] }}<span> *</span>
                  <span class="error">{{ form_fields.email_error[lang] }}</span>
                </span>
                <svg
                  width="300%"
                  height="50px"
                  viewBox="0 0 1200 60"
                  preserveAspectRatio="none"
                  class="graphic"
                >
                  <path
                    d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0"
                  ></path>
                </svg>
              </div>
              <div class="input-container area">
                <textarea
                  ref="text"
                  v-on:blur="loseFocus($refs.text)"
                  required
                  v-model="formDetails.message"
                  :class="{ full: formDetails.message.length > 0 }"
                  type="text"
                  maxlength="800"
                  name="text"
                  class="int-input text"
                ></textarea>

                <span class="input-about"
                  >{{ form_fields.message[lang] }}<span> </span>
                  <span class="error">{{
                    form_fields.message_error[lang]
                  }}</span>
                </span>
                <svg
                  width="300%"
                  height="50px"
                  viewBox="0 0 1200 60"
                  preserveAspectRatio="none"
                  class="graphic"
                >
                  <path
                    d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0"
                  ></path>
                </svg>
              </div>
              <div class="sub">
                <button type="submit">{{ form_fields.send[lang] }}</button
                ><ok v-if="sent" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>
<script>
import axios from "axios";
import ok from "@/assets/svg/ok.vue";
import menuComponent from "@/components/MenuComponent";
import footerComponent from "@/components/FooterComponent";
import { mapState } from "vuex";

export default {
  components: {
    menuComponent,
    footerComponent,
    ok,
  },
  data() {
    return {
      formDetails: {
        name: "",
        email: "",
        message: "",
      },
      sent: false,
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.locale,
      advantages: (state) => state?.fields.advantages || [],
      best_choise: (state) => state?.fields.best_choise || {},
      block_names: (state) => state?.fields.block_names || [],
      contacts: (state) => state?.fields.contacts || {},
      discounts: (state) => state?.fields.discounts || [],
      form_background: (state) => !!state?.fields.form_background,
      form_fields: (state) => state?.fields.form_fields || {},
      form_heading: (state) => state?.fields.form_heading || {},
      form_sub_heading: (state) => state?.fields.form_sub_heading || {},
      heading: (state) => state?.fields.heading || {},
      sub_choises: (state) => state?.fields.sub_choises || [],
      work_process: (state) => state?.fields.work_process || [],
      projects: (state) => state.projects,
    }),
    lang() {
      return this.$route.fullPath.includes("/en/") ? "ru" : "lv";
    },
  },
  methods: {
    validate() {
      let errors = false;
      let emailPattern = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/;
      if (this.formDetails["email"] != "") {
        console.log("email match");
      } else {
        errors = true;
        this.$refs.email.closest(".input-container").classList.add("error");
      }
      if (this.formDetails["name"] != "") {
        console.log("name match");
      } else {
        errors = true;
        this.$refs.name.closest(".input-container").classList.add("error");
      }
      if (this.formDetails["message"] != "") {
        console.log("message match");
      } else {
        errors = true;
        this.$refs.text.closest(".input-container").classList.add("error");
      }
      if (!errors && !this.sending) {
        this.sending = true;
        this.submitForm();
      }
    },
    async submitForm() {
      await axios
        .post("/form.php", {
          name: this.formDetails.name,
          email: this.formDetails.email,
          message: this.formDetails.message,
        })
        .then((res) => {
          this.formDetails.name = "";
          this.formDetails.email = "";
          this.formDetails.message = "";
          this.sent = true;
        });
    },
    loseFocus(el) {
      el.closest(".input-container").classList.remove("error");
    },
  },
};
</script>
<style lang="scss">
.form {
  color: #fff;
  display: flex;
  min-height: 100vh;
  padding: 107px 0;
  background-color: #212226;
  align-items: center;
  .form-wrap {
    display: flex;
    .info {
      width: 50%;
      .more-info {
        padding-top: 30px;
        p {
          font-size: 12px;
          color: #b8bac1;
          letter-spacing: 1px;
          margin: 0;
          line-height: 19px;

          span {
            font-weight: 300;
          }
        }
      }
    }
    .fields {
      form {
        display: flex;
        flex-wrap: wrap;
      }
      .heading {
        font-weight: 500;
        color: #fff;
        font-size: 18px;
        line-height: 24px;

        padding-bottom: 50px;
      }
      width: 50%;
      button {
        font-size: 14px;
        color: #000;
        text-transform: capitalize;
        background: #fff;
        height: 40px;
        width: 154px;
        border-radius: 20px;
        font-weight: 300;
      }
      .sub {
        display: flex;
        align-items: center;
        svg {
          margin-left: 10px;
          height: 20px;
          width: auto;
          fill: green;
        }
      }
    }
  }
}
.input-container {
  width: calc(50% - 20px);
  height: fit-content;
  position: relative;
  overflow: hidden;
  margin-bottom: 25px;
  &:first-of-type {
    margin-right: 20px;
  }

  &.area {
    width: 100%;
  }
  .input-about {
    position: absolute;
    text-transform: capitalize;
    top: 25px;
    left: 0;
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 14px;
    letter-spacing: normal;
    color: #ffffff;
    z-index: 5;
    transition: 0.7s;
    span {
      font-size: 12px;
      color: #4a90e2;
      &.error {
        display: none;
        transition: 0s;
        font-size: 11px;
      }
    }
  }
  input,
  textarea {
    background-color: transparent;
    position: relative;
    z-index: 20;
    margin: 13px 0 0 0;
    padding: 0 1px;
    width: 100%;
    box-sizing: border-box;
    border: none;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.92;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    transition: 0.5s;
  }
  textarea {
    padding-top: 8px;
    resize: none;
    height: 135px;
  }
  input {
    height: 37px;
  }
  .graphic {
    fill: none;
    position: absolute;
    bottom: 0;
    left: 0;
    stroke: #d5d5d5;
    pointer-events: none;
    -webkit-transition: -webkit-transform 0.7s, stroke 0.7s;
    transition: transform 0.7s, stroke 0.7s;
    -webkit-transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
  }
  .int-input:focus ~ .input-about,
  .int-input.full ~ .input-about {
    color: #909090;
    transform: translateY(-22px);
    font-size: 12px;
    opacity: 0.9;
  }
  .int-input:focus ~ .graphic,
  .int-input.full ~ .graphic {
    stroke: #4a90e2;
    -webkit-transform: translateX(-66.6%);
    transform: translateX(-66.6%);
  }
  &.error {
    .int-input ~ .graphic {
      stroke: #ca3b3b;
    }
    .input-about {
      span.error {
        display: inline-block;
        color: #ca3b3b !important;
        margin-left: 5px;
      }
    }
  }
}
@media only screen and (max-width: 750px) and (min-width: 300px) {
  .form {
    .form-wrap {
      flex-wrap: wrap;
      .info {
        width: 100%;
        display: none;
      }
      .fields {
        width: 100%;
      }
    }
  }
}
@media only screen and (max-width: 550px) and (min-width: 300px) {
  .input-container {
    width: 100% !important;
    input,
    textarea {
      font-size: calc(100vw / 320 * 12);
    }
    input {
      height: calc(100vw / 320 * 37);
    }
    .input-about {
      font-size: calc(100vw / 320 * 12);
      top: calc(100vw / 320 * 25);
    }
    .int-input:focus ~ .input-about,
    .int-input.full ~ .input-about {
      color: #909090;
      transform: translateY(calc(100vw / 320 * -22));
      font-size: calc(100vw / 320 * 11);
      opacity: 0.9;
    }
    &:first-of-type {
      margin-right: 0px;
    }
  }
}
</style>
