import axios from "axios";

const api = axios.create({
  baseURL: `https://rubyfloors.fr/admin/wp-json/wp/v2/`,
});

export const getPages = async (id = 9) => {
  return api
    .get(`pages/${id}`)
    .then(({ data }) => {
      console.log(`page ${id}:`, data.acf);
      return data.acf;
    })
    .catch(() => {
      return {};
    });
};
export const getProjects = async () => {
  return await api
    .get(`projects`)
    .then(({ data }) => {
      console.log(data);
      return data.map(({ acf }) => {
        return {
          title: acf.project_name,
          description: acf.project_descrription,
          images: acf.projects_images,
        };
      });
    })
    .catch(() => {
      return [];
    });
};
