<template>
  <section>
    <menuComponent :lang="lang" :sub="subMenu" :names="[]" />
    <footerComponent :lang="lang" />
    <floors :data="currentPage" :lang="lang" />
  </section>
</template>
<script>
import menuComponent from "@/components/MenuComponent";
import footerComponent from "@/components/FooterComponent";
import floors from "@/components/Floors";
import { mapActions } from "vuex";
import { mapState } from "vuex";

const pages = {
  "riekstkoka-vairogparkets": {
    id: 273,
    name: {
      ru: "Паркет из ореха",
      lv: "Riekstkoka vairogparkets",
    },
    link: "/riekstkoka-vairogparkets",
  },
  laminats: {
    id: 269,
    name: {
      ru: "Ламинат",
      lv: "Lamināts",
    },
    link: "/laminats",
  },
  makslinieciskais: {
    id: 275,
    name: {
      ru: "Художественный",
      lv: "Mākslinieciskais",
    },
    link: "/makslinieciskais",
  },
  "trisslanu-parkets": {
    id: 297,
    name: {
      ru: "Трехслойная паркетная доска",
      lv: "Trīsslāņu parkets",
    },
    link: "/trisslanu-parkets",
  },
  vinils: {
    id: 271,
    name: {
      ru: "Винил",
      lv: "Vinils",
    },
    link: "/vinils",
  },
};

export default {
  data() {
    return {};
  },
  methods: {
    ...mapActions(["setSinglePage"]),
  },
  mounted() {
    console.log(pages[this.$route.params.floor]);
    this.setSinglePage(pages[this.$route.params.floor]?.id);
  },
  computed: {
    subMenu() {
      const menu = Object.keys(pages).map((key) => {
        return {
          ...pages[key],
          active: pages[key].id == this.pageId,
        };
      });
      console.log(`pages`, Object.keys(pages));
      const home = {
        id: 9,
        name: {
          ru: "Главная",
          lv: "Galvenā",
        },
        link: "/",
        active: false,
      };

      return [home].concat(menu);
    },
    pageId() {
      return pages[this.$route.params.floor]?.id?.toString();
    },
    lang() {
      return this.$route.fullPath.includes("/en/") ? "ru" : "lv";
    },
    currentPage() {
      if (this.pageUpdate) {
        return this.pageData[this.pageId];
      } else {
        return 0;
      }
    },
    ...mapState({
      pageData: (state) => state.singlePage,
      pageUpdate: (state) => state.pageUpdate,
    }),
  },
  components: {
    menuComponent,
    footerComponent,
    floors,
  },
};
</script>
