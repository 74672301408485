<template>
  <section class="footer">
    <div class="container">
      <div class="logo">
        <img src="@/assets/logo.png" />
        <div class="address">
          14 Settimelli Lazare VILLEFRANCHE sur Mer France 06230
        </div>
      </div>
      <div class="contact">
        <router-link :to="lng + '/contact'"
          ><Button>{{ $t("contact") }}</Button></router-link
        >
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: ["lang"],
  computed: {
    lng() {
      if (this.$parent.lang == "en") {
        return "/en";
      } else {
        return "";
      }
    },
  },
};
</script>
<style lang="scss">
.footer {
  z-index: 20;
  height: 50px;
  width: 100%;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #242529;
  display: flex;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .logo {
    display: flex;
    align-items: center;
    justify-self: flex-start;
    img {
      height: 25px;
      width: auto;
    }
    .address {
      font-size: 13px;
      font-weight: 300;
      color: #9a9a9a;
      padding-left: 10px;
    }
  }
  .contact {
    button {
      font-size: 14px;
      color: #fff;
      border: 2px solid #8f939f;
      background: transparent;
      height: 31px;
      width: 154px;
      border-radius: 16px;
      transition: 1s;
      &:hover {
        color: #000;
        border: none;
        background-color: #fff;
      }
    }
  }
}
@media only screen and (max-width: 750px) and (min-width: 300px) {
  .footer {
    .logo {
      padding-right: 10px;
      img {
        display: none;
      }
    }
    .contact {
      button {
        max-width: calc(100vw / 320 * 100);
      }
    }
  }
}
</style>
