<template>
  <section class="menu" :class="{ active: menuActive }">
    <div class="container">
      <div class="logo" :href="lang">
        <a :href="'/' + lng.substr(1)"><img src="@/assets/logo.png"/></a>
        <div class="burger" @click="menuActive = !menuActive">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div class="list">
        <!-- <div v-if="names && names.length > 0"> -->
        <button
          class="list-item"
          v-for="(block, i) in names.length ? [names[0], names[2], names[3], names[6]]: []"
          :key="i"
          @click="scrollInto(i)"
        >
          {{ block.link[$parent.lang] }}
        </button>
        <!-- </div> -->
        <!-- <div v-if="sub && sub.length > 0"> -->
        <button
          class="list-item"
          v-for="(block, i) in sub"
          :key="i"
          :class="{ active: block.active }"
        >
          <a :href="lng + block.link">
            {{ block.name[$parent.lang] }}
          </a>
        </button>
        <!-- </div> -->

        <div class="lang-switch">
          <div class="lang-list">
            <router-link
              class="lang"
              :class="{ active: lang == 'lv' }"
              :to="link.lv"
              :active="active"
              >FR</router-link
            >

            <router-link
              class="lang"
              :class="{ active: lang == 'ru' }"
              :active="active"
              :to="link.ru"
              >EN</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: ["names", "lang", "sub"],
  data() {
    return {
      menuActive: false,
    };
  },
  computed: {
    link() {
      if (this.lang == "ru") {
        return {
          lv:
            "/" +
            this.$route.fullPath.substring(
              this.$route.fullPath.indexOf("/en/") + 4
            ),
          ru:
            "/en/" +
            this.$route.fullPath.substring(
              this.$route.fullPath.indexOf("/en/") + 4
            ),
        };
      } else {
        return {
          lv: this.$route.fullPath,
          ru: "/en" + this.$route.fullPath,
        };
      }
    },
    lng() {
      if (this.$parent.lang == "ru") {
        return "/en";
      } else {
        return "";
      }
    },
  },
  methods: {
    scrollInto(i) {
      this.menuActive = false;
      var block = this.$parent.$refs.block[i].querySelector(".slide-number");
      var blockOffset;
      if (i > 5050) {
        blockOffset = block.getBoundingClientRect().top + block.scrollTop;
        blockOffset = blockOffset - Math.round(window.innerHeight / 10);
        window.scrollBy({ top: blockOffset, left: 0, behavior: "smooth" });
      } else {
        blockOffset = 0;
        this.$parent.$refs.block[i].scrollIntoView({
          behavior: "smooth",
        });
      }
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
.menu {
  z-index: 20;
  height: 55px;
  width: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #242529;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .logo {
    img {
      height: 25px;
      width: auto;
    }
  }
  .list {
    display: flex;
    text-transform: capitalize;
    .list-item {
      padding: 0 15px;
      font-size: 14px;
      color: #9a9a9a;
      cursor: pointer;
      display: flex;
      align-items: center;
      transition: 0.5s;
      &:hover,
      &.active {
        color: #fff;
      }
    }
  }
  .lang-switch {
    display: flex;
    flex-direction: column;
    margin-left: calc(100vw / 1366 * 80);
    .heading {
      color: #fff;
      font-size: 13px;
      font-weight: 300;
      line-height: 15px;
    }
    .lang-list {
      display: flex;
      padding-top: 3px;
      .lang {
        border: 2px solid #808082;
        width: 50px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        color: #808082;
        font-size: 13px;
        cursor: pointer;
        font-weight: 300;
        line-height: 20px;
        margin: 0 10px 0 0;
        &.active {
          background-color: #fff;
          border: none;
          color: #000;
        }
      }
    }
  }
}
@media only screen and (max-width: 1000px) and (min-width: 300px) {
  .menu {
    overflow: visible;
    height: 10vh;
    z-index: 21;
    &.active {
      .list {
        transform: translateY(0);
      }
      .logo {
        .burger {
          div {
            &:first-of-type {
              transform: rotate(-45deg);
              top: 50%;
            }
            &:last-of-type {
              transform: rotate(45deg);
              top: 50%;
            }
            &:nth-of-type(2) {
              left: 10px;
              opacity: 0;
            }
          }
        }
      }
    }
    .list {
      z-index: 10;
      position: absolute;
      width: 100%;
      top: 10vh;
      left: 0;
      flex-direction: column;
      height: 0vh;
      background-color: #242529;
      text-align: center;
      overflow: hidden;
      height: 90vh;
      padding: 2vh 0;
      transform: translateY(-100%);
      transition: 1s;
      .list-item {
        height: 8vh;
        font-size: 3vh;
        justify-content: center;
      }
      .lang-switch {
        margin: 5vh 0 0 0;
        justify-content: center;
        align-items: center;
        .lang-list {
          .lang {
            margin: 0 3vw;
            height: 5vh;
            width: 20vw;
            border-radius: 3vh;
            font-size: 2vh;
          }
        }
      }
    }
    .logo {
      background-color: #242529;
      z-index: 20;
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: space-between;
      align-items: center;
      .burger {
        width: 24px;
        height: 16px;
        position: relative;
        div {
          transition: 0.2s ease-in;
          width: 100%;
          height: 1px;
          background-color: #fff;
          position: absolute;
          left: 0;
          &:nth-of-type(1) {
            top: 0;
          }
          &:nth-of-type(2) {
            top: 50%;
          }
          &:nth-of-type(3) {
            top: 100%;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1052px) and (min-width: 1001px) {
  .menu {
    .list {
      .list-item {
        padding: 0 10px;
      }
    }
  }
}
</style>
